import { render, staticRenderFns } from "./needDeliverOrderList.vue?vue&type=template&id=58ece00a&scoped=true&"
import script from "./needDeliverOrderList.vue?vue&type=script&lang=js&"
export * from "./needDeliverOrderList.vue?vue&type=script&lang=js&"
import style0 from "./needDeliverOrderList.vue?vue&type=style&index=0&id=58ece00a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ece00a",
  null
  
)

export default component.exports